/* eslint-disable */
import axios from "axios";
import { Field, Form, Formik, setFieldValue } from "formik";
import request from "graphql-request";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "gatsby-plugin-react-intl";
import * as Yup from "yup";
import "../assets/css/career.scss";
import Layout from "../components/layout";
import Loading from "../components/loading";
import SingleCareer from "../components/singleCareer";
import uploadIcon from "../images/input-file.svg";
import ShowProvider from "../components/showProvider";
import Head from "../components/head";
import ButtonLoading from "../components/buttonLoading";
const CareerPage = ({ pageContext }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [buttons, setButtons] = useState({});
  const [phrase, setPhrase] = useState({});
  const [offer, setOffer] = useState("0");
  const [sending, setSending] = useState(4);
  const [uploaded, setUploaded] = useState({});
  const strapiHost = process.env.GATSBY_API_URL;
  const min = phrase.validationTooShort;
  const max = phrase.validationTooLong;
  const req = phrase.validationRequired;
  const ema = phrase.validationEmail;
  const pho = phrase.validationPhone;
  const off = phrase.validationOfferFromList;
  const rcv = phrase.validationAttachFile;
  const formSchema = Yup.object().shape({
    name: Yup.string().min(2, min).max(50, max).required(req),
    mail: Yup.string().email(ema).required(req),
    company: Yup.string().min(2, min).max(50, max),
    phone: Yup.string()
      .required(req)
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        pho
      ),
    offer: Yup.string().notOneOf(["0"], off),
    cv: Yup.mixed()
      .nullable()
      .notRequired()
      .test("FILE_FORMAT", phrase.validationPDFfile, (value) => {
        const regex = /(?:\.([^.]+))?$/;
        return regex.exec(value && value.name)[0] === ".pdf";
      }),
    terms: Yup.bool().oneOf([true], "sadadsasd !!!"),
  });
  const formRef = useRef();
  const fileInputRef = useRef();
  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `  {
          career(locale: "${pageContext.intl.language}")  {
            pageTitle
            title
            description
            img {
              url
            }
            offer {
              offer_description
              id
              title
            }
            formFields {
              formTitle
              nameAndSurname
              email
              companyName
              phone
              addAttachment
            }
          }
          phrase(locale: "${pageContext.intl.language}") {
            validationTooShort
            validationTooShort
            validationRequired
            validationEmail
            validationAcceptTerms
            validationPhone
            validationOfferFromList
            validationAttachFile
            validationPDFfile
            AcceptTermsForm
            AcceptTerms2Form
            sendCV
          }
          button(locale: "${pageContext.intl.language}") {
            acceptterms
            sendapplication
         
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.career);
      setButtons(items.button);
      setPhrase(items.phrase);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Layout contactUs={false}>
        {loading ? (
          <Loading />
        ) : (
          <ShowProvider>
            <div className="career__wrapper">
              <div className="career__section1">
                <div className="career__left">
                  <img
                    src={`${strapiHost}${data.img.url}`}
                    className="career__img"
                  />
                </div>
                <div className="career__right">
                  <h1 className="career__right-title">{data.title}</h1>
                  <p className="career__right-description">
                    {data.description}
                  </p>
                </div>
              </div>
              <h2 className="career__title2">{data.title2}</h2>
              <div className="career__wrapper-offer">
                {data.offer.map(({ offer_description, title, id }, key) => (
                  <SingleCareer
                    title={title}
                    description={offer_description}
                    id={id}
                    key={key}
                    setOffer={setOffer}
                    formRef={formRef}
                    phrase={phrase}
                  />
                ))}
              </div>
              <div className="career__form__container" ref={formRef}>
                <h2 className="career__form__title">
                  {data.formFields.formTitle}
                </h2>
                <div className="career__form-wrap">
                  <Formik
                    initialValues={{
                      name: "",
                      mail: "",
                      company: "",
                      phone: "",
                      offer: "0",
                      cv: "",
                      terms: false,
                    }}
                    validationSchema={formSchema}
                    validateOnMount={false}
                    onSubmit={(values, { resetForm }) => {
                      setSending(1);
                      // formData.append("files.cv", uploaded.cv);
                      const interval = setTimeout(async () => {
                        try {
                          let formData = new FormData();
                          formData.append("files", values.cv);
                          const uploadedFile = await axios.post(
                            `${strapiHost}/upload`,
                            formData
                          );
                          // setSending(0);

                          const ans = await axios.post(`${strapiHost}/cvs`, {
                            ...values,
                            cv: uploadedFile.data[0].id,
                            offer: data.offer.find((e) => (e.id = offer)).title,
                          });

                          setSending(2);
                          resetForm();
                          // setFieldValue("offer", "0");
                        } catch (err) {
                          setSending(0);
                        }
                      }, 2000);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      values,
                      setFieldValue,
                    }) => {
                      useEffect(() => {
                        setFieldValue("offer", offer);
                      }, [offer]);

                      return (
                        <Form className="career__form">
                          <Row>
                            <Col className="career__form__col">
                              <label
                                className="career__form__label"
                                htmlFor="name"
                              >
                                {data.formFields.nameAndSurname}
                              </label>
                              <Field
                                id="name"
                                name="name"
                                className="career__form__input"
                              />
                              <p className="career__form__validation">
                                {errors.name && touched.name && (
                                  <>{errors.name}</>
                                )}
                              </p>
                            </Col>
                            <Col className="career__form__col" xs={12} md={6}>
                              <label
                                className="career__form__label"
                                htmlFor="mail"
                              >
                                {data.formFields.email}
                              </label>
                              <Field
                                id="mail"
                                name="mail"
                                className="career__form__input"
                              />
                              <p className="career__form__validation">
                                {errors.mail && touched.mail && (
                                  <>{errors.mail}</>
                                )}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="career__form__col" xs={12} md={6}>
                              <label
                                className="career__form__label"
                                htmlFor="company"
                              >
                                {data.formFields.companyName}
                              </label>
                              <Field
                                id="company"
                                name="company"
                                className="career__form__input"
                              />
                              <p className="career__form__validation">
                                {errors.company && touched.company && (
                                  <>{errors.company}</>
                                )}
                              </p>
                            </Col>
                            <Col className="career__form__col" xs={12} md={6}>
                              <label
                                className="career__form__label"
                                htmlFor="phone"
                              >
                                {data.formFields.phone}
                              </label>
                              <Field
                                id="phone"
                                name="phone"
                                className="career__form__input"
                              />
                              <p className="career__form__validation">
                                {errors.phone && touched.phone && (
                                  <>{errors.phone}</>
                                )}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="career__form__col" xs={12}>
                              <label
                                className="career__form__label career__form__label--select"
                                htmlFor="offer"
                              >
                                {phrase.validationOfferFromList}
                              </label>
                              <Field
                                id="offer"
                                name="offer"
                                as="select"
                                type="select"
                                value={values.offer}
                                className="form-select career__form__input career__form__input--select"
                                onChange={(e) => {
                                  setOffer(e.target.value);
                                }}
                              >
                                <option value="0">
                                  {phrase.validationOfferFromList}
                                </option>
                                {data.offer.map(
                                  ({ description, title, id }, key) => (
                                    <option value={id} key={key}>
                                      {title}
                                    </option>
                                  )
                                )}
                              </Field>
                              <p className="career__form__validation">
                                {errors.offer && touched.offer && (
                                  <>{errors.offer}</>
                                )}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="career__form__col" xs={12} md={6}>
                              <label
                                className="career__form__label career__form__label--file"
                                htmlFor="cv"
                              >
                                {data.formFields.addAttachment}
                                <img src={uploadIcon} />
                                {fileInputRef.current &&
                                  fileInputRef.current.files[0] &&
                                  fileInputRef.current.files[0].name}
                              </label>
                              <Field id="cv" name="cv">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    name="cv"
                                    id="cv"
                                    className="career__form__input career__form__input--file "
                                    ref={fileInputRef}
                                    onChange={(e) => {
                                      setFieldValue("cv", e.target.files[0]);
                                    }}
                                    // {...field}
                                  />
                                )}
                              </Field>
                              <p className="career__form__validation">
                                {errors.cv && touched.cv && <>{errors.cv}</>}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-flex flex-column gap-2">
                              <label
                                className="workers__worker__checkbox-container justify-content-start mt-3 mb-0"
                                htmlFor="terms"
                              >
                                <Field
                                  type="checkbox"
                                  name="terms"
                                  id="terms"
                                />
                                <div className="workers__worker__checkbox"></div>
                                {phrase.AcceptTermsForm}
                                {"  "}
                                <Link to="/terms">
                                  {phrase.AcceptTerms2Form}
                                </Link>
                              </label>
                              <div className="workers__contact__input-container__validation">
                                {errors.terms && touched.terms && (
                                  <>{phrase.validationAcceptTerms}</>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="career__form__col career__form__col--submit">
                              <ButtonLoading
                                title={buttons.sendapplication}
                                className="btn btn-primary btn-xl"
                                type="submit"
                                state={sending}
                                setState={setSending}
                              />
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </ShowProvider>
        )}
      </Layout>
    </>
  );
};

export default CareerPage;
