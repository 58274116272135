import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import "../assets/css/singleCareer.scss";
import chevronDown from "../images/chevron-down.svg";

function SingleCareer(
  { title, description, id, setOffer, formRef, phrase },
  key
) {
  const [show, setShow] = useState(false);
  return (
    <div className="singleCareer" key={key}>
      <div
        className="singleCareer__title-wrapper"
        onClick={() => {
          setShow(!show);
        }}
      >
        <h2 className="singleCareer__title">{title}</h2>
        <button>
          <img src={chevronDown} className={`${show && "toggled"}`} />
        </button>
      </div>
      {show && (
        <div className="singleCareer__desc1">
          <ReactMarkdown>{description}</ReactMarkdown>
          <button
            className="btn btn-primary"
            onClick={() => {
              formRef.current.scrollIntoView();
              setOffer(id);
            }}
          >
            {phrase.sendCV}
          </button>
        </div>
      )}
    </div>
  );
}

export default SingleCareer;
